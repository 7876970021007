import React from 'react'

export default class Contact extends React.Component {
    render() {
        return (
            <div className="ContactBar">
                Are you listening?  Let me know: dan.burford@gmail.com
            </div>
        )
    }
}
