import React from 'react'
import Api from './Api'
import Auth from './Auth'
import { Link } from 'react-router-dom'

export default class Tracks extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
          tracks: [],
          page: 0,
          sort: "id",
          order: "asc",
          ingest: "",
          searchRequest: { title: '', artist: '', album: '', type: '' }
        }

        this.pageSize = 20

        this.fetchTracks = this.fetchTracks.bind(this)
        this.nextPage = this.nextPage.bind(this)
        this.previousPage = this.previousPage.bind(this)
        this.trackTable = this.trackTable.bind(this)
        this.trackRow = this.trackRow.bind(this)
        this.updateSearch = this.updateSearch.bind(this)
        this.performSearch = this.performSearch.bind(this)
        this.searchIsEmpty = this.searchIsEmpty.bind(this)
        this.toggleSort = this.toggleSort.bind(this)
        this.toggleUntagged = this.toggleUntagged.bind(this)
        this.toggleUnicorn = this.toggleUnicorn.bind(this)
    }

    componentDidMount() {
        this.fetchTracks()
        this.props.setDetail([])
    }

    fetchTracks() {
        if (this.searchIsEmpty()) {
            const sort = this.state.sort
            const page = this.state.page
            const pageSize = this.pageSize
            const order = this.state.order
            Api.fetchTracks(sort, page, this.pageSize, order)
              .then( data => this.setState({ tracks: data }) )
        } else {
            this.performSearch()
        }
    }

    toggleSort(column) {
        if (this.state.sort === column) {
            if (this.state.order === 'asc') {
                this.setState({ order: 'desc' }, state => { this.fetchTracks() } )
            } else {
                this.setState({ order: 'asc' }, state => { this.fetchTracks() } )
            }
        } else {
            this.setState({ sort: column, order: 'asc' }, state => { this.fetchTracks() } )
        }
    }

    toggleUntagged() {
        if (this.state.untagged) {
            const newRequest = this.state.searchRequest
            delete newRequest.untagged
            this.setState({ searchRequest: newRequest, untagged: false }, () => { this.fetchTracks() })
        } else {
            const newRequest = this.state.searchRequest
            newRequest.untagged = true
            this.setState({ searchRequest: newRequest, untagged: true }, () => { this.fetchTracks() })
        }
    }

    toggleUnicorn() {
        if (this.state.unicorn) {
            const newRequest = this.state.searchRequest
            delete newRequest.unicorn
            this.setState({ searchRequest: newRequest, unicorn: false }, () => { this.fetchTracks() })
        } else {
            const newRequest = this.state.searchRequest
            newRequest.unicorn = true
            this.setState({ searchRequest: newRequest, unicorn: true }, () => { this.fetchTracks() })
        }
    }

    trackTable(tracks) {
        return <table className="TrackList">
            <thead>
                <tr>
                  <th className="TrackList" onClick={ () => this.toggleSort('id') }>
                    id
                    { this.state.sort === 'id' ?
                        <span className="material-icons SortArrow">{ this.state.order === 'asc' ? 'north' : 'south' }</span>
                        : null
                    }
                  </th>
                  <th className="TrackList" onClick={ () => this.toggleSort('type') }>
                    type
                    { this.state.sort === 'type' ?
                        <span className="material-icons SortArrow">{ this.state.order === 'asc' ? 'north' : 'south' }</span>
                        : null }
                  </th>
                  <th className="TrackList" onClick={ () => this.toggleSort('artist') }>
                    artist
                    { this.state.sort === 'artist' ?
                        <span className="material-icons SortArrow">{ this.state.order === 'asc' ? 'north' : 'south' }</span>
                        : null }
                  </th>
                  <th className="TrackList" onClick={ () => this.toggleSort('title') }>
                    title
                    { this.state.sort === 'title' ?
                        <span className="material-icons SortArrow">{ this.state.order === 'asc' ? 'north' : 'south' }</span>
                        : null }
                  </th>
                  <th className="TrackList" onClick={ () => this.toggleSort('album') }>
                    album
                    { this.state.sort === 'album' ?
                        <span className="material-icons SortArrow">{ this.state.order === 'asc' ? 'north' : 'south' }</span>
                        : null }
                  </th>
                </tr>
            </thead>
            <tbody>
                { tracks.map( track => this.trackRow(track) ) }
            </tbody>
        </table>
    }

    trackRow(track) {
        return <tr key={ track.id } className="TrackList">
            <td className="TrackIdColumn TrackList">{ track.id }</td>
            <td className="TrackTypeColumn TrackList">{ track.type }</td>
            <td className="TrackArtistColumn TrackList">{ track.artist }</td>
            <td onClick={ () => this.props.setDetail([ { panel: 'trackEditor', props: { trackId: track.id } } ], true) }
                className="TrackTitleColumn TrackList"><span className="TrackTitle Button">{ track.title }</span></td>
            <td className="TrackAlbumColumn TrackList">{ track.album }</td>
        </tr>
    }

    nextPage() {
        this.setState(
          (state) => { return { page: state.page + 1 } },
          () => {
            this.fetchTracks()
          }
        )
    }

    previousPage() {
        this.setState(
          (state) => { return { page: Math.max(0, state.page - 1) } },
          () => this.fetchTracks()
        )
    }

    searchBox() {
        return <table className="SearchTable">
          <tbody>
            <tr>
              <td className="SearchTableLabel">title</td>
              <td className="SearchTableValue">
                <input type="text" value={ this.state.searchRequest.title } onChange={ event => this.updateSearch('title', event.target.value) } />
              </td>
            </tr>
            <tr>
              <td className="SearchTableLabel">artist</td>
              <td className="SearchTableValue">
                <input type="text" value={ this.state.searchRequest.artist } onChange={ event => this.updateSearch('artist', event.target.value) } />
              </td>
            </tr>
            <tr>
              <td className="SearchTableLabel">album</td>
              <td className="SearchTableValue">
                <input type="text" value={ this.state.searchRequest.album } onChange={ event => this.updateSearch('album', event.target.value) } />
              </td>
                <td className="SearchTableLabel">unicorns</td>
                <td className="SearchTableValue">
                    <input type="checkbox" value={ this.state.unicorn } onChange={ event => this.toggleUnicorn() } />
                </td>
            </tr>
            <tr>
              <td className="SearchTableLabel">type</td>
              <td className="SearchTableValue">
                  <select value={ this.state.searchRequest.type } onChange={ event => this.updateSearch('type', event.target.value) }>
                    <option value=""></option>
                    <option value="AMBIENCE">Ambience</option>
                    <option value="BED">Bed</option>
                    <option value="BROKEN">Broken</option>
                    <option value="BUMP">Bump</option>
                    <option value="COMMERCIAL">Commercial</option>
                    <option value="DROP">Drop</option>
                    <option value="ENV">Environment</option>
                    <option value="FULL_TRACK">Full Track</option>
                    <option value="NOISE">Noise</option>
                    <option value="RHYTHM">Rhythm</option>
                    <option value="VOICE">Voice</option>
                  </select>
              </td>
                <td className="SearchTableLabel">untagged</td>
                <td className="SearchTableValue">
                    <input type="checkbox" value={ this.state.untagged } onChange={ event => this.toggleUntagged() } />
                </td>
            </tr>
          </tbody>
        </table>
    }

    performSearch() {
        const searchRequest = this.state.searchRequest
        searchRequest.page = this.state.page
        searchRequest.pageSize = this.state.pageSize
        searchRequest.sort = this.state.sort
        searchRequest.order = this.state.order
        Api.search(searchRequest).then( tracks => this.setState({ tracks }) )
    }

    updateSearch(field, value) {
        const searchRequest = this.state.searchRequest
        searchRequest[field] = value
        this.setState({ searchRequest, sort: 'id', page: 0, pageSize: 20 }, () => { this.fetchTracks() })
    }

    searchIsEmpty() {
        const searchRequest = this.state.searchRequest
        return searchRequest.title.length == 0 &&
               searchRequest.artist.length == 0 &&
               searchRequest.album.length == 0 &&
               searchRequest.type === '' &&
                !searchRequest.hasOwnProperty('untagged') &&
                !searchRequest.hasOwnProperty('unicorn')
    }

    render() {
        // Now Playing data looks like: [{"id":33,"album":"","artist":"tspigot Radio Bump","duration":18.050612,"path":"/media/bumpH_nonsense.mp3","playWindow":"BOTH","title":"bumpH_nonsense","type":"BUMP","lastPlayEpoch":1641701576},{"id":772,"album":"","artist":"Tom Paolantonio, Adam Primack","duration":167.836735,"path":"/media/quiet_stream.mp3","playWindow":"BOTH","title":"quiet_stream","type":"ENV","lastPlayEpoch":1641171967}]
        return <div>
          <div className="Search">
          { this.searchBox() }
          </div>
          <table className="TracksContainer">
            <tbody>
              <tr>
                <td className="TrackControls">
                  <span onClick={ this.previousPage } className="material-icons Button">chevron_left</span>
                  <span onClick={ this.nextPage } className="material-icons Button">chevron_right</span>
                </td>
              </tr>
              <tr>
                <td>
                  { this.trackTable(this.state.tracks) }
                </td>
              </tr>
            </tbody>
          </table>
        </div>
    }
}