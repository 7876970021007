import React from 'react'
import OperatorStatus from './OperatorStatus'

export default class Dashboard extends React.Component {

    constructor(props) {
        super(props)
        this.state = {}
    }

    render() {
        return <div>
            <h2>Dashboard</h2>
            <OperatorStatus />
        </div>
    }
}