import React from 'react'
import Api from './Api'
import OperatorNowPlaying from './OperatorNowPlaying'
import OperatorHistory from './OperatorHistory'

export default class OperatorStatus extends React.Component {

    constructor(props) {
        super(props);
        this.state = { playing: [] }

        this.updateData = this.updateData.bind(this)
    }

    componentDidMount() {
        this.updateData()
        this.updateTask = setInterval(this.updateData, 5000)
    }

    componentWillUnmount() {
        clearInterval(this.updateTask)
    }

    updateData() {
        Api.nowPlaying().then( data => this.setState({ playing: data }) )
    }

    render() {
        // Now Playing data looks like: [{"id":33,"album":"","artist":"tspigot Radio Bump","duration":18.050612,"path":"/media/bumpH_nonsense.mp3","playWindow":"BOTH","title":"bumpH_nonsense","type":"BUMP","lastPlayEpoch":1641701576},{"id":772,"album":"","artist":"Tom Paolantonio, Adam Primack","duration":167.836735,"path":"/media/quiet_stream.mp3","playWindow":"BOTH","title":"quiet_stream","type":"ENV","lastPlayEpoch":1641171967}]
        return <div className="Status">
          <table className="PanelTable">
          <tbody>
            <tr className="Status">
              <td className="PanelTableHeader">Now Playing</td>
            </tr>
            <tr className="Status">
              <td>
                <OperatorNowPlaying setDetail={ this.props.setDetail } />
              </td>
            </tr>
            <tr className="Status">
              <td className="PanelTableHeader">History</td>
            </tr>
            <tr className="Status">
              <td>
                <OperatorHistory  setDetail={ this.props.setDetail } />
              </td>
            </tr>
          </tbody>
          </table>
        </div>
    }
}
