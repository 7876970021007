import React from 'react'
import Api from './Api'
import Spinner from './spinner.svg'

export default class Track extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            track: null,
            unmodifiedTrack: null,
            message: "",
            playing: false,
            queued: false,
            axes: [],
            replayGain: { trackGain: "Not set", trackPeak: "Not set" },
            loadingReplayGain: false,
            fadeInString: "",
            fadeOutString: ""
        }

        this.audio = null

        this.getTrackId = this.getTrackId.bind(this)
        this.loadTrackData = this.loadTrackData.bind(this)
        this.trackContent = this.trackContent.bind(this)
        this.updateTrackState = this.updateTrackState.bind(this)
        this.updateTrackDuration = this.updateTrackDuration.bind(this)
        this.saveTrack = this.saveTrack.bind(this)
        this.togglePlay = this.togglePlay.bind(this)
        this.toggleQueue = this.toggleQueue.bind(this)
        this.axesList = this.axesList.bind(this)
        this.rowForAxis = this.rowForAxis.bind(this)
        this.updateAxis = this.updateAxis.bind(this)
        this.analyzeReplayGain = this.analyzeReplayGain.bind(this)
        this.saveReplayGain = this.saveReplayGain.bind(this)
        this.updateReplayGain = this.updateReplayGain.bind(this)
        this.updateEq = this.updateEq.bind(this)
        this.resetEqLo = this.resetEqLo.bind(this)
        this.resetEqHi = this.resetEqHi.bind(this)
        this.renderLastPlay = this.renderLastPlay.bind(this)
    }

    componentDidMount() {
        this.loadTrackData(this.getTrackId())
    }

    componentDidUpdate(prevProps) {
        if (prevProps.trackId != this.props.trackId) {
            this.loadTrackData(this.getTrackId())
        }
    }

    getTrackId() {
        const routerTrack = this.props.router ? this.props.router.params.trackId : null
        const propsTrack = this.props.trackId
        const trackId = parseInt(propsTrack ? propsTrack : routerTrack)
        return trackId
    }

    loadTrackData(trackId) {
        Api.trackDetails(trackId).then( details => {
            this.setState({ track: details, unmodifiedTrack: details, fadeInString: details.fadeIn.toString(), fadeOutString: details.fadeOut.toString() })
            Api.getAxes().then( axes => {
                const trackAxes = details.axes
                this.setState({
                    axes: axes.map( axis => {
                        const trackAxis = trackAxes.find( it => axis.id === it.id )
                        const spin = typeof trackAxis !== 'undefined' ? trackAxis.spin : null
                        return {
                            id: axis.id,
                            yin: axis.yin,
                            yang: axis.yang,
                            spin: spin
                        }
                    })
                })
            })
        })
        Api.getQueue().then( queue => {
            const trackQueued = queue.some( track => track.id === this.getTrackId() )
            this.setState({ queued: trackQueued })
        })
        this.setState({ loadingReplayGain: true })
        Api.getTrackReplayGain(trackId).then( replayGain => this.setState({ replayGain, loadingReplayGain: false }) )
    }

    updateTrackState(field, event) {
        const track = this.state.track
        track[field] = event.target.value
        this.setState({ track })
    }

    updateTrackFadeIn(event) {
        this.setState({ fadeInString: event.target.value })
    }

    updateTrackFadeOut(event) {
        this.setState({ fadeOutString: event.target.value })
    }

    updateTrackDuration(event) {
        const track = this.state.track
        track.duration = parseFloat(event.target.value)
        this.setState({ track })
    }

    saveTrack() {
        const track = this.state.track
        track.fadeIn = parseFloat(this.state.fadeInString)
        track.fadeOut = parseFloat(this.state.fadeOutString)
        Api.updateTrack(track).then( message => {
            this.setState({ message: message })
            setTimeout(() => this.setState({ message: '' }), 5000)
        }).catch( error => {
            this.setState({ message: 'Track update failed: ' + error })
            setTimeout(() => this.setState({ message: '' }), 5000)
        })
    }

    togglePlay() {
        if (this.state.playing) {
            this.audio.pause()
            this.setState({ playing: false })
        } else {
            this.audio = Api.previewTrack(this.state.track.id)
            this.audio.onended = () => { this.setState({ playing: false }) }
            this.audio.play()
            this.setState({ playing: true })
        }
    }

    toggleQueue() {
        if (this.state.queued) {
            Api.unqueueTrack(this.state.track.id).then( () => {
                this.setState({ queued: false })
            })
        } else {
            Api.queueTrack(this.state.track.id).then( () => {
                this.setState({ queued: true })
            })
        }
    }

    axesList() {
        return <div className="Axes">
          <span className="TrackEditLabel">axes</span>
          <table className="AxesSelector">
            <tbody>
            { this.state.axes.map( axis => this.rowForAxis(axis) ) }
            </tbody>
          </table>
        </div>
    }

    rowForAxis(axis) {
        const pickYin = () => this.updateAxis(axis, 'YIN')
        const pickYang = () => this.updateAxis(axis, 'YANG')
        const pickNone = () => this.updateAxis(axis, null)
        const yinSelected = axis.spin === 'YIN'
        const yangSelected = axis.spin === 'YANG'
        const noneSelected = axis.spin === null
        return <tr key={ axis.id }>
          <td className={ "Button " + (yinSelected ? "AxisSelected" : "AxisUnselected")  } onClick={ pickYin }>
            <span>{ axis.yin }</span>
          </td>
          <td className={ "Button " + (noneSelected ? "AxisSelected" : "AxisUnselected") } onClick={ pickNone }>
            <span>neutral</span>
          </td>
          <td className={ "Button " + (yangSelected ? "AxisSelected" : "AxisUnselected") } onClick={ pickYang }>
            <span>{ axis.yang }</span>
          </td>
        </tr>
    }

    updateAxis(axis, value) {
        const axes = this.state.axes
        const stateAxis = axes.find( it => it.id === axis.id )
        stateAxis.spin = value
        const track = this.state.track
        track.axes = axes.filter( axis => axis.spin !== null  )
        track.untagged = false
        Api.updateTrack(track).then( () => {
            this.setState({ track, axes })
        })
    }

    analyzeReplayGain() {
        this.setState({ loadingReplayGain: true })
        Api.analyzeTrackReplayGain(this.getTrackId()).then( replayGain => {
            this.setState({ replayGain, loadingReplayGain: false })
        }).catch( error => this.setState({ loadingReplayGain: false }) )
    }

    updateReplayGain(field, event) {
        const replayGain = this.state.replayGain
        replayGain[field] = event.target.value
        this.setState({ replayGain })
    }

    saveReplayGain() {
        this.setState({ loadingReplayGain: true })
        Api.saveTrackReplayGain(this.getTrackId(), this.state.replayGain).then( output => {
            this.setState({ loadingReplayGain: false })
        }).catch( error => this.setState({ loadingReplayGain: false }) )
    }

    updateEq(setting, event) {
        this.updateTrackState("eq" + setting, event)
        Api.adjustEq(this.state.track.id, setting, event.target.value).then(output => {

        })
    }

    resetEqLo() {
        this.updateEq("LoFreq", { target: { value: 100.0 }})
        this.updateEq("LoGain", { target: { value: 0.0 }})
    }

    resetEqHi() {
        this.updateEq("HiFreq", { target: { value: 8000.0 }})
        this.updateEq("HiGain", { target: { value: 0.0 }})
    }

    renderLastPlay(epoch) {
        const sec = (Date.now() / 1000) - epoch
        const hr = Math.floor(sec / (60 * 60))
        let text = `${hr} hours`
        if (epoch < 1) {
            text = `never`
        } else if (hr > 24) {
            const days = Math.floor(sec / (60 * 60 * 24))
            text = `${days} days`
        }
        return <div>
            { text }
        </div>
    }

    renderEq() {

    }

    trackContent() {
        const track = this.state.track
        const isMainTrack = ["AMBIENCE","BED","FULL_TRACK","COMMERCIAL"].includes(this.state.track.type)
        const replayGain = this.state.replayGain
        if (replayGain.trackGain === null) { replayGain.trackGain = 'Not set' }
        if (replayGain.trackPeak === null) { replayGain.trackPeak = 'Not set' }

        return <div className="TrackDetails">
          <table className="TrackEdit">
            <thead>
              <tr><th className="TrackEditorHeader" colSpan="4">Track Editor</th></tr>
            </thead>
            <tbody>
              <tr>
                <td className="TrackEditLabel">title</td>
                <td className="TrackEditValue">
                  <input className="TrackEdit" type="text" value={ track.title } onChange={ event => this.updateTrackState('title', event) } />
                </td>
                <td className="TrackEditAxes" rowSpan="7">
                  { this.axesList() }
                </td>
                <td className="TrackEditIcons" rowSpan="7">
                  <span onClick={ this.togglePlay } className="material-icons Button">{ this.state.playing ? 'stop_circle' : 'play_circle' }</span>
                  <br/>
                  <span onClick={ this.toggleQueue } className="material-icons Button">{ this.state.queued ? 'playlist_remove' : 'playlist_play' }</span>
                </td>
              </tr>
              <tr>
                <td className="TrackEditLabel">artist</td>
                <td className="TrackEditValue">
                  <input className="TrackEdit" type="text" value={ track.artist } onChange={ event => this.updateTrackState('artist', event) } />
                </td>
              </tr>
              <tr>
                <td className="TrackEditLabel">album</td>
                <td className="TrackEditValue">
                  <input className="TrackEdit" type="text" value={ track.album } onChange={ event => this.updateTrackState('album', event) } />
                </td>
              </tr>
              <tr>
                <td className="TrackEditLabel">type</td>
                <td className="TrackEditValue">
                  <select value={ track.type } onChange={ event => this.updateTrackState('type', event) }>
                    <option value="AMBIENCE">Ambience</option>
                    <option value="BED">Bed</option>
                    <option value="BROKEN">Broken</option>
                    <option value="BUMP">Bump</option>
                    <option value="COMMERCIAL">Commercial</option>
                    <option value="DROP">Drop</option>
                    <option value="ENV">Environment</option>
                    <option value="FULL_TRACK">Full Track</option>
                    <option value="NOISE">Noise</option>
                    <option value="RHYTHM">Rhythm</option>
                    <option value="VOICE">Voice</option>
                  </select>
                </td>
              </tr>
              <tr>
                  <td className="TrackEditLabel">path</td>
                  <td className="TrackEditValue">
                      <input className="TrackEdit" type="text" value={ track.path } onChange={ event => this.updateTrackState('path', event) } />
                  </td>
              </tr>
              <tr>
                <td className="TrackEditLabel">duration</td>
                <td className="TrackEditValue">
                  <input className="TrackEdit" type="text" value={ track.duration } onChange={ event => this.updateTrackDuration(event) } />
                </td>
              </tr>
              <tr>
                  <td className="TrackEditLabel">fade in</td>
                  <td>
                      <input className="TrackSubEdit" type="text" value={ this.state.fadeInString } onChange={ event => this.updateTrackFadeIn(event) } />
                      <span className="TrackEditLabel">out </span><input className="TrackSubEdit" type="text" value={ this.state.fadeOutString } onChange={ event => this.updateTrackFadeOut(event) } />
                  </td>
              </tr>
              { isMainTrack &&
                  <tr>
                      <td className="TrackEditLabel">bpm</td>
                      <td>
                          <input className="TrackSubEdit" type="text" value={ track.musicBpm }
                                 onChange={event => this.updateTrackState('musicBpm', event)}/>
                          <span className="TrackEditLabel">key </span><input className="TrackSubEdit" type="text" value={ track.musicKey }
                                            onChange={event => this.updateTrackState('musicKey', event)}/>
                      </td>
                  </tr>
              }
              { isMainTrack &&
                  <tr>
                      <td className="TrackEditLabel">EQ lo</td>
                      <td className="TrackEditValue" colSpan={3}>
                          <input type="range" min={20.0} max={500.0} value={ track.eqLoFreq } onChange={ event=>this.updateEq('LoFreq', event) } />
                          freq &nbsp;|&nbsp; gain
                          <input type="range" min={-20.0} max={20.0} value={ track.eqLoGain } onChange={ event=>this.updateEq('LoGain', event) } />
                          <button className="StandardButton" onClick={ this.resetEqLo }>reset</button>
                      </td>
                  </tr>
              }
              { isMainTrack &&
                  <tr>
                  <td className="TrackEditLabel">EQ hi</td>
                  <td className="TrackEditValue" colSpan={3}>
                  <input type="range" min={1000.0} max={15000.0} value={ track.eqHiFreq } onChange={ event=>this.updateEq('HiFreq', event) } />
                  freq &nbsp;|&nbsp; gain
                  <input type="range" min={-20.0} max={20.0} value={ track.eqHiGain } onChange={ event=>this.updateEq('HiGain', event) } />
                  <button className="StandardButton" onClick={ this.resetEqHi }>reset</button>
                  </td>
                  </tr>
              }
              <tr>
                <td className="TrackEditButtons" colSpan="2">
                  <button className="StandardButton" onClick={ this.saveTrack }>Save</button>
                </td>
              </tr>
              <tr className="TrackReplayGainTopRow">
                <td className="TrackEditLabel">gain</td>
                <td className="TrackEditValue">
                  <input className="TrackEdit" type="text" value={ replayGain.trackGain } onChange={ event => this.updateReplayGain('trackGain', event) } />
                </td>
              </tr>
              <tr>
                <td className="TrackEditLabel">peak</td>
                <td className="TrackEditValue">
                  <input className="TrackEdit" type="text" value={ replayGain.trackPeak } onChange={ event => this.updateReplayGain('trackPeak', event) } />
                </td>
              </tr>
              <tr>
                <td className="TrackEditButtons" colSpan="2">
                  <span className="TrackEditSpinner">{ this.state.loadingReplayGain ? <img className="TrackEditSpinner" src={ Spinner } width="15" height="15" /> : null }</span>
                  <button className="StandardButton" onClick={ this.analyzeReplayGain }>Analyze</button>
                  <button className="StandardButton" onClick={ this.saveReplayGain }>Save</button>
                </td>
              </tr>
              <tr>
                  <td className="TrackEditLabel">last play</td>
                  <td className="TrackEditValue">
                      { this.renderLastPlay(track.lastPlayEpoch) }
                  </td>
              </tr>
            </tbody>
          </table>
          <div className="TrackEditMessage">
          { this.state.message }
          </div>
        </div>
    }

    render() {
        return <div className="TrackPage">
        { this.state.track == null ? <p>Loading...</p> : this.trackContent() }
        </div>
    }

}
