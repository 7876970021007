import React from 'react'
import Api from './Api'

export default class BrainMood extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            axes: []
        }

        this.rowForAxis = this.rowForAxis.bind(this)
        this.updateAxis = this.updateAxis.bind(this)
        this.loadMoodData = this.loadMoodData.bind(this)
    }

    componentDidMount() {
        this.loadMoodData()
    }

    loadMoodData() {
        Api.getMood().then(moodAxes => {
            Api.getAxes().then(axes => {
                this.setState({
                    axes: axes.map(axis => {
                        const moodAxis = moodAxes.find(it => axis.id === it.id )
                        const spin = typeof moodAxis !== 'undefined' ? moodAxis.spin : null
                        return {
                            id: axis.id,
                            yin: axis.yin,
                            yang: axis.yang,
                            spin: spin
                        }
                    })
                })
            })
        })
    }

    render() {
        return <div>
            <table className="AxesSelector">
                <tbody>
                { this.state.axes.map( axis => this.rowForAxis(axis) ) }
                </tbody>
            </table>
        </div>
    }

    rowForAxis(axis) {
        const pickYin = () => this.updateAxis(axis, 'YIN')
        const pickYang = () => this.updateAxis(axis, 'YANG')
        const pickNone = () => this.updateAxis(axis, null)
        const yinSelected = axis.spin === 'YIN'
        const yangSelected = axis.spin === 'YANG'
        const noneSelected = axis.spin === null
        return <tr key={ axis.id }>
            <td className={ "Button " + (yinSelected ? "AxisSelected" : "AxisUnselected")  } onClick={ pickYin }>
                <span>{ axis.yin }</span>
            </td>
            <td className={ "Button " + (noneSelected ? "AxisSelected" : "AxisUnselected") } onClick={ pickNone }>
                <span>neutral</span>
            </td>
            <td className={ "Button " + (yangSelected ? "AxisSelected" : "AxisUnselected") } onClick={ pickYang }>
                <span>{ axis.yang }</span>
            </td>
        </tr>
    }

    updateAxis(axis, value) {
        const axes = this.state.axes
        const stateAxis = axes.find( it => it.id === axis.id )
        stateAxis.spin = value
        Api.updateMood(axes.filter( axis => axis.spin !== null )).then(() => {
            this.setState({ axes: axes })
        })
    }
}
