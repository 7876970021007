import React from 'react'
import Api from './Api'
import './App.css'

export default class Banner extends React.Component {

    constructor(props) {
        super(props)
        this.myO = 0.0
        this.targetO = 0.5
        this.targetHue = 200

        this.state = {
            imageUri: "",
            style: {
                opacity: this.myO
            }
        }

        this.updateImage = this.updateImage.bind(this)
        this.getImage = this.getImage.bind(this)
        this.animate = this.animate.bind(this)
    }

    componentDidMount() {
        this.updateImage()
        this.updateTask = setInterval(this.updateImage, 60000)
        this.animate()
        this.animateTask = setInterval(this.animate, 30)
    }

    componentWillUnmount() {
        clearInterval(this.updateTask)
        clearInterval(this.animateTask)
    }

    updateImage() {
        this.targetO = 0.0
        this.getImageTask = setInterval(this.getImage, 4000)
    }

    getImage() {
        clearInterval(this.getImageTask)
        Api.getImage().then( data => this.setState({ imageUri: data }) )
        this.targetO = 0.5
    }

    animate() {
        if (this.myO > this.targetO) {
            this.myO = this.myO - 0.005
        } else if (this.myO < this.targetO) {
            this.myO = this.myO + 0.005
        }
        this.setState({ style: {
            opacity: this.myO
        }})
    }

    render() {
        return <img src={ "http://radio.tspigot.net/images/" + this.state.imageUri } className="Banner" style={this.state.style} />
    }
}
