import React from 'react'
import Api from './Api'

export default class SpeechSynthesis extends React.Component {

    constructor(props) {
        super(props);
        this.state = { speechText: "" }

        this.submitSpeech = this.submitSpeech.bind(this)
    }

    componentDidMount() {
    }

    componentWillUnmount() {
    }

    submitSpeech() {
        Api.submitSpeech(this.state.speechText).then( response => {
            this.setState({ speechResponse: response })
            setTimeout(() => this.setState({ speechResponse: "", speechText: "" }), 5000)
        }).catch( error => {
            this.setState({ speechResponse: error })
            setTimeout(() => this.setState({ speechResponse: "" }), 5000)
        })
    }

    render() {
        return <table className="PanelTable">
            <tbody>
            <tr className="Speech">
             <td className="PanelTableHeader">Speech Synth</td>
            </tr>
            <tr className="Speech">
             <td>
               <input className="SpeechInput" type="text" value={ this.state.speechText } onChange={ event => this.setState({ speechText: event.target.value }) } />
               <button className="StandardButton" onClick={ () => this.submitSpeech() }>Say It</button>
               <div className="SpeechResponse">{ this.state.speechResponse }</div>
             </td>
            </tr>
            </tbody>
        </table>
    }
}
