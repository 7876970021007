import React from 'react'
import { Link, Navigate, Outlet } from 'react-router-dom'
import Auth from './Auth'
import LogTailer from './LogTailer'
import OperatorLive from './OperatorLive'
import OperatorStatus from './OperatorStatus'
import Track from './Track'
import Tracks from './Tracks'
import Utils from './Utils'
import Brain from "./Brain"

export default class Operator extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            mainPanel: 'status',
            detailPanel: [],
            detailPinned: false,
        }

        this.logout = this.logout.bind(this)
        this.mainPanelContent = this.mainPanelContent.bind(this)
        this.mainPanelSelector = this.mainPanelSelector.bind(this)
        this.setDetailContent = this.setDetailContent.bind(this)
        this.toggleDetailPanelPinned = this.toggleDetailPanelPinned.bind(this)
    }

    logout() {
        Auth.logout()
        this.setState({ loggedOut: true })
    }

    mainPanelSelector() {
        const buttonClasses = "material-icons Button PanelSelectorButton"
        return <div className="MainPanelContent">
          <div className="MainPanelSelector">
            <span className={ buttonClasses + (this.state.mainPanel === 'status' ? " PanelButtonSelected" : "") }
                  onClick={ () => this.setState({ mainPanel: 'status' }) }>history</span>
            <span className={ buttonClasses + (this.state.mainPanel === 'search' ? " PanelButtonSelected" : "") }
                  onClick={ () => this.setState({ mainPanel: 'search' }) }>search</span>
            <span className={ buttonClasses + (this.state.mainPanel === 'live' ? " PanelButtonSelected" : "") }
                  onClick={ () => this.setState({ mainPanel: 'live' }) }>mic</span>
            <span className={ buttonClasses + (this.state.mainPanel === 'utils' ? " PanelButtonSelected" : "") }
                  onClick={ () => this.setState({ mainPanel: 'utils' }) }>inventory</span>
            <span className={ buttonClasses + (this.state.mainPanel === 'brain' ? " PanelButtonSelected": "") }
                  onClick={ () => this.setState( { mainPanel: 'brain' }) }>sort</span>
          </div>
        </div>
    }

    detailPanelSelector() {
        const buttonClasses = "material-icons Button PanelSelectorButton"
        return <div className="MainPanelSelector">
          <span className={ buttonClasses + (this.state.detailPinned ? " PanelButtonSelected" : "") }
                onClick={ this.toggleDetailPanelPinned }>push_pin</span>
        </div>
    }

    setDetailContent(content, pin = false) {
        if (!this.state.detailPinned || pin) {
            this.setState({ detailPanel: content, detailPinned: pin })
        }
    }

    toggleDetailPanelPinned() {
        this.setState({ detailPinned: !this.state.detailPinned })
    }

    mainPanelContent() {
        switch (this.state.mainPanel) {
            case 'status':
                return <OperatorStatus setDetail={ this.setDetailContent } />
            case 'search':
                return <Tracks setDetail={ this.setDetailContent } />
            case 'live':
                return <OperatorLive setDetail={ this.setDetailContent } />
            case 'utils':
                return <Utils setDetail={ this.setDetailContent } />
            case 'brain':
                return <Brain setDetail={ this.setDetailContent } />
        }
    }

    detailPanelContent() {
        return <div className="DetailPanelContent">
          { this.state.detailPanel.map( (data, index) => {
                switch (data.panel) {
                    case 'trackEditor':
                        return <Track key={ index } { ...data.props } />
                    case 'logTailer':
                        return <LogTailer key={ index } { ...data.props } />
                }
            })
          }
        </div>
    }

    render() {
        if (!Auth.userIsAuthenticated()) {
            console.log("User is not authenticated, navigating to /login")
            return <Navigate to="/login" replace={ true } />
        }

        return <div className="OperatorContainer">
          <div className="MainPanel">
            { this.mainPanelSelector() }
            { this.mainPanelContent() }
          </div>
          <div className="DetailPanel">
            { this.detailPanelSelector() }
            { this.detailPanelContent() }
          </div>
        </div>
    }
}
