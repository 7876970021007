import React from 'react'
import Api from './Api'

export default class Axes extends React.Component {

    constructor(props) {
        super(props)
        this.state = { axes: [], yin: '', yang: '' }

        this.fetchAxes = this.fetchAxes.bind(this)
        this.setYin = this.setYin.bind(this)
        this.setYang = this.setYang.bind(this)
        this.addAxis = this.addAxis.bind(this)
        this.updateAxis = this.updateAxis.bind(this)
        this.saveAxis = this.saveAxis.bind(this)
        this.deleteAxis = this.deleteAxis.bind(this)
    }

    componentDidMount() {
        this.fetchAxes()
    }

    fetchAxes() {
        Api.getAxes().then( axes => {
            this.setState({ axes })
        })
    }

    setYin(event) {
        this.setState({ yin: event.target.value })
    }

    setYang(event) {
        this.setState({ yang: event.target.value })
    }

    addAxis() {
        Api.addAxis({ yin: this.state.yin, yang: this.state.yang, notes: this.state.notes }).then( () => {
            this.setState({ yin: '', yang: '' })
            this.fetchAxes()
        })
    }

    updateAxis(event, field, axis) {
        const value = event.target.value
        const axes = this.state.axes
        axes.find( it => it.id === axis.id )[field] = value
        this.setState({ axes })
    }

    saveAxis(axis) {
        Api.updateAxis(axis).then( () => this.fetchAxes() )
    }

    deleteAxis(axis) {
        Api.deleteAxis(axis).then( () => this.fetchAxes() )
    }

    render() {
        return <div className="Axes">
          <table className="AxesTable">
            <thead>
              <tr><th className="AxesTableHeader" colSpan="4">Axes</th></tr>
            </thead>
            <tbody>
            { this.state.axes.map( axis =>
                <tr className="AxesRow" key={ axis.id }>
                  <td className="AxesColumn"><input type="text" value={ axis.yin } onChange={ event => this.updateAxis(event, 'yin', axis) } /></td>
                  <td className="AxesColumn"><input type="text" value={ axis.yang } onChange={ event => this.updateAxis(event, 'yang', axis) } /></td>
                  <td className="AxesColumn">
                    <span className="material-icons AxesIcon Button" onClick={ () => this.saveAxis(axis) }>save</span>
                    <span className="material-icons AxesIcon Button" onClick={ () => this.deleteAxis(axis) }>delete</span>
                  </td>
                </tr>
              )
            }
            <tr className="AxesRow">
              <td className="AxesColumn"><input type="text" value={ this.state.yin } onChange={ this.setYin } placeholder="Yin" /></td>
              <td className="AxesColumn"><input type="text" value={ this.state.yang } onChange={ this.setYang } placeholder="Yang" /></td>
              <td className="AxesColumn"><span className="material-icons AxesIcon Button" onClick={ this.addAxis }>add</span></td>
            </tr>
            </tbody>
          </table>
        </div>
    }
}