import React from 'react'
import Api from './Api'
import Axes from './Axes'

export default class Login extends React.Component {

    constructor(props) {
        super(props)
        this.state = {}

        this.ingest = this.ingest.bind(this)
        this.showLogs = this.showLogs.bind(this)
    }

    componentDidMount() {
        this.props.setDetail([])
    }

    ingest() {
        Api.ingest().then( data => this.setState({ message: data }) )
        setTimeout(() => { this.setState({ message: '' }) }, 5000)
    }

    showLogs() {
        this.props.setDetail([{ panel: 'logTailer', props: {} }])
    }

    render() {
        return <div>
          <table>
            <tbody>
              <tr>
                <td className="UtilButtonColumn">
                  <span onClick={ this.ingest } className="material-icons Button PanelSelectorButton UtilButton">loop</span>
                </td>
                <td className="UtilTextColumn">
                  <span className="UtilText">Ingest tracks</span>
                </td>
              </tr>
              <tr>
                <td className="UtilButtonColumn">
                  <span onClick={ this.showLogs } className="material-icons Button PanelSelectorButton UtilButton">article</span>
                </td>
                <td className="UtilTextColumn">
                  <span className="UtilText">Tail logfile</span>
                </td>
              </tr>
            </tbody>
          </table>
          <div className="Message">{ this.state.message }</div>
          <Axes />
      </div>
    }
}
