import request from 'superagent'
import Auth from './Auth.js'
import { apiUrl } from './Config.js'

export default class Api {

    static delete(path) {
        if (Auth.userIsAuthenticated()) {
            return request.delete(apiUrl + path)
                .withCredentials()
                .auth(Auth.username(), Auth.password(), { type: 'basic'})
        } else {
            return request.delete(apiUrl + path)
        }
    }

    static get(path) {
        if (Auth.userIsAuthenticated()) {
            return request.get(apiUrl + path)
                .withCredentials()
                .auth(Auth.username(), Auth.password(), { type: 'basic'})
        } else {
            return request.get(apiUrl + path)
        }
    }

    static put(path, object) {
        if (Auth.userIsAuthenticated()) {
            return request.put(apiUrl + path)
                .withCredentials()
                .auth(Auth.username(), Auth.password(), { type: 'basic'})
                .send(object)
        } else {
            return request.put(apiUrl + path).send(object)
        }
    }

    static post(path, object) {
        if (Auth.userIsAuthenticated()) {
            return request.post(apiUrl + path)
                .withCredentials()
                .auth(Auth.username(), Auth.password(), { type: 'basic'})
                .send(object)
        } else {
            return request.put(apiUrl + path).send(object)
        }
    }

    static async analyzeTrackReplayGain(trackId) {
        let response = await Api.get(`/track/replayGain/analyze/${trackId}`)
        return response.body
    }

    static async checkCredentials(username, password) {
        await request.get(apiUrl + '/checkCredentials')
            .withCredentials()
            .auth(username, password, { type: 'basic' })
        return true
    }

    static async addAxis(axis) {
        let response = await Api.post('/axis', axis)
        return response.text
    }

    static async deleteAxis(axis) {
        let response = await Api.delete(`/axis/${axis.id}`)
        return response.text
    }

    static async fetchTracks(sort = "id", offset = 0, pageSize = 20, order = "asc") {
        let response = await Api.get(`/tracks/${sort}/${offset}/${pageSize}/${order}`)
        return response.body
    }

    static async getAxes() {
        let response = await Api.get('/axes')
        return response.body
    }

    static async getMood() {
        let response = await Api.get('/mood')
        return response.body
    }

    static async getColors() {
        let response = await Api.get('/uiColors')
        return response.body
    }

    static async getImage() {
        let response = await Api.get('/image')
        return response.text
    }

    static async getQueue() {
        let response = await Api.get('/queue')
        return response.body
    }

    static async getSlogan() {
        let response = await Api.get('/slogan')
        return response.text
    }

    static async getTrackAxes(trackId) {
        let response = await Api.get(`/track/axes/${trackId}`)
        return response.body
    }

    static async getTrackReplayGain(trackId) {
        let response = await Api.get(`/track/replayGain/${trackId}`)
        return response.body
    }

    static async history() {
        let response = await Api.get('/history')
        return response.body
    }

    static async ingest() {
        let response = await Api.get('/ingest')
        return response.text
    }

    static async nowPlaying() {
        let response = await Api.get('/nowPlaying')
        return response.body
    }

    static async upNext() {
        let response = await Api.get('/upNext')
        return response.body
    }

    static async refreshNext() {
        let response = await Api.get('/refreshNext')
        return response.body
    }

    static previewTrack(trackId) {
        const authedUrl = apiUrl.replace(/\/\//, `//${Auth.username()}:${Auth.password()}@`)
        const url = `${authedUrl}/previewTrack/${trackId}`
        return new Audio(url)
    }

    static async queueTrack(trackId) {
        let response = await Api.get(`/queue/add/${trackId}`)
        return response.text
    }

    static async saveTrackReplayGain(trackId, replayGain) {
        let response = await Api.put(`/track/replayGain/${trackId}`, replayGain)
        return response.body
    }

    static async search(request) {
        let response = await Api.post('/search', request)
        return response.body
    }

    static async setTrackAxes(trackId, axes) {
        let response = await Api.put(`/track/axes/${trackId}`, axes)
        return response.text
    }

    static async submitSpeech(text) {
        let response = await Api.post(`/speak`, { text })
        return response.text
    }

    static async tailLogs() {
        let response = await Api.get(`/logs`)
        return response.text
    }

    static async trackDetails(trackId) {
        let response = await Api.get(`/track/${trackId}`)
        return response.body
    }

    static async updateAxis(axis) {
        let response = await Api.put(`/axis/${axis.id}`, axis)
        return response.text
    }

    static async updateMood(mood) {
        let response = await Api.put(`/mood`, mood)
        return response.text
    }

    static async unqueueTrack(trackId) {
        let response = await Api.get(`/queue/remove/${trackId}`)
        return response.text
    }

    static async updateTrack(track) {
        let response = await Api.put(`/track/${track.id}`, track)
        return response.text
    }

    static async adjustEq(trackId, setting, value) {
        let response = await Api.get(`/eq/${trackId}/${setting}/${value}`)
        return response.text
    }
}
