export default class Auth {

    static userIsAuthenticated() {
        return 'username' in localStorage && localStorage['username'] !== ''
    }

    static username() {
        return localStorage['username']
    }

    static password() {
        return localStorage['password']
    }

    static logout() {
        localStorage.removeItem('username')
        localStorage.removeItem('password')
    }
}
