import React from 'react'
import Api from './Api'
import './App.css'

export default class Slogan extends React.Component {

    constructor(props) {
        super(props)
        this.myO = 0.0
        this.targetO = 1.0

        this.state = { text: "", style: {
            opacity: this.myO
        } }

        this.updateText = this.updateText.bind(this)
        this.getText = this.getText.bind(this)
        this.animate = this.animate.bind(this)
    }

    componentDidMount() {
        this.updateText()
        this.updateTask = setInterval(this.updateText, 90000)
        this.animate()
        this.animateTask = setInterval(this.animate, 30)
    }

    componentWillUnmount() {
        clearInterval(this.updateTask)
        clearInterval(this.animateTask)
    }

    updateText() {
        this.targetO = 0.0
        this.getTextTask = setInterval(this.getText, 2500)
    }

    getText() {
        clearInterval(this.getTextTask)
        Api.getSlogan().then( data => this.setState({ text: data }) )
        this.targetO = 1.0
    }

    animate() {
        if (this.myO > this.targetO) {
            this.myO = this.myO - 0.015
        }
        if (this.myO < this.targetO) {
            this.myO = this.myO + 0.015
        }
        this.setState({ style: {
            opacity: this.myO
        }})
    }

    render() {
        return <div className="Tagline" style={this.state.style}>{ this.state.text }</div>
    }
}
