import React from 'react'
import Api from './Api'
import SpeechSynthesis from './SpeechSynthesis'

export default class OperatorLive extends React.Component {

    constructor(props) {
        super(props);
        this.state = { }

        this.submitSpeech = this.submitSpeech.bind(this)
    }

    componentDidMount() {
        this.props.setDetail([])
    }

    componentWillUnmount() {
    }

    submitSpeech() {
        Api.submitSpeech(this.state.speechText)
    }

    render() {
        return <div className="Status">
            <SpeechSynthesis />
        </div>
    }
}
