import React from 'react'
import Api from './Api'

export default class NowPlaying extends React.Component {

    constructor(props) {
        super(props);
        this.state = { playing: [], upNext: [] }

        this.updateData = this.updateData.bind(this)
    }

    componentDidMount() {
        this.updateData()
        this.updateTask = setInterval(this.updateData, 5000)
    }

    componentWillUnmount() {
        clearInterval(this.updateTask)
    }

    updateData() {
        Api.upNext().then( upNext =>
            Api.nowPlaying().then( nowPlaying =>
                this.setState({ upNext: upNext, playing: nowPlaying })
            )
        )
    }

    render() {
        const mainTrackTypes = [ 'FULL_TRACK', 'AMBIENCE', 'BED', 'COMMERCIAL', 'BUMP' ]
        const otherTrackTypes = [ 'VOICE', 'ENV', 'RHYTHM' ]
        const mainTracks = this.state.playing.filter( track => mainTrackTypes.includes(track.type) )
        const otherTracks = this.state.playing.filter( track => otherTrackTypes.includes(track.type) )
        const upNext = this.state.upNext
        return <div className="NowPlaying">
          <ul className="Tracks">
            { mainTracks.map( track =>
                <li className="MainTrack" key={ track.id }>
                  <span className="Title"><i><b>{ track.title }</b></i></span>
                  <span className="Artist">{ track.artist ? " by " + track.artist : "" }</span>
                </li>
              )
            }
          </ul>
          { otherTracks.length > 0 ?
              <div>
              <span className="With">with</span>
              <ul className="Tracks">
                { otherTracks.map( track =>
                    <li className="Track" key={ track.id }>
                      <span className="Title"><i><b>{ track.title }</b></i></span>
                      <span className="Artist">{ track.artist ? " by " + track.artist : "" }</span>
                    </li>
                  )
                }
              </ul>
              </div>
            : null
          }
        { upNext.length > 0 ?
            <div>
                <span className="UpNext">up next</span>
                <ul className="Tracks">
                    { upNext.map( track =>
                            <li className="Track" key={ track.id }>
                                <span className="Title"><i><b>{ track.title }</b></i></span>
                                <span className="Artist">{ track.artist ? " by " + track.artist : "" }</span>
                            </li>
                        )
                    }
                </ul>
            </div>
            : null
        }
        </div>
    }
}
