import React from 'react'
import Api from './Api.js'
import History from './History.js'
import { Link } from 'react-router-dom'

export default class OperatorNowPlaying extends React.Component {

    constructor(props) {
        super(props);
        this.state = { playing: [], upNext: [], refreshing: false }
        this.mainTrackTypes = [ 'FULL_TRACK', 'AMBIENCE', 'BED', 'COMMERCIAL', 'BUMP' ]

        this.updateData = this.updateData.bind(this)
        this.refreshNext = this.refreshNext.bind(this)
        this.renderNextButton = this.renderNextButton.bind(this)
    }

    componentDidMount() {
        this.updateData()
        this.updateTask = setInterval(this.updateData, 5000)
    }

    componentWillUnmount() {
        clearInterval(this.updateTask)
    }

    updateData() {
        Api.upNext().then( upNext =>
            Api.nowPlaying().then( data => {
                this.setState({ playing: data, upNext: upNext, refreshing: false })
                const mainTracks = data.filter( track => this.mainTrackTypes.includes(track.type) )
                if (mainTracks.length > 0) {
                    this.props.setDetail([ { panel: 'trackEditor', props: { trackId: mainTracks[0].id } } ])
                }
            })
        )
    }

    refreshNext() {
        this.setState({ refreshing: true })
        Api.refreshNext().then( res => {
            this.updateData()
        }).catch(e => {
            this.setState({ refreshing: false })
        })
    }

    renderNextButton() {
        if (this.state.refreshing) return <span> </span>
        return <span className="material-icons Button" onClick={()=>this.refreshNext()}>refresh</span>
    }

    render() {
        const mainTracks = this.state.playing.filter( track => this.mainTrackTypes.includes(track.type) )
        const otherTracks = this.state.playing.filter( track => !this.mainTrackTypes.includes(track.type) )
        const upNext = this.state.upNext
        return <div className="NowPlaying">
          <ul className="Tracks">
            { mainTracks.map( track =>
                <li className="MainTrack" key={ track.id }>
                  <span className="Title Button" onClick={ () => this.props.setDetail([ { panel: 'trackEditor', props: { trackId: track.id } } ], true) }><i><b>{ track.title }</b></i></span>
                  <span className="Artist">{ track.artist ? " by " + track.artist : "" }</span>
                </li>
              )
            }
          </ul>
          { otherTracks.length > 0 ?
              <div>
              <span className="With">with</span>
              <ul className="Tracks">
                { otherTracks.map( track =>
                    <li className="Track" key={ track.id }>
                      <span className="Title Button" onClick={ () => this.props.setDetail([ { panel: 'trackEditor', props: { trackId: track.id } } ], true) }><i><b>{ track.title }</b></i></span>
                      <span className="Artist">{ track.artist ? " by " + track.artist : "" }</span>
                    </li>
                  )
                }
              </ul>
              </div>
            : null
          }
        { upNext.length > 0 ?
            <div>
                <span className="UpNext">up next</span>
                <ul className="Tracks">
                    { upNext.map( track =>
                        <li className="Track" key={ track.id }>
                            <span className="Title Button" onClick={ () => this.props.setDetail([ { panel: 'trackEditor', props: { trackId: track.id } } ], true) }><i><b>{ track.title }</b></i></span>
                            <span className="Artist">{ track.artist ? " by " + track.artist : "" }</span>&nbsp;&nbsp;{ this.renderNextButton() }
                        </li>
                    )
                    }
                </ul>
            </div>
            : null
        }
        </div>
    }

    colorForTrackType(type) {
        switch (type) {
            case 'FULL_TRACK':
                return '#a442f5'
            case 'BUMP':
                return '#f5dd42'
            case 'AMBIENCE':
                return '#42cef5'
            case 'BED':
                return '#42f5c2'
            case 'VOICE':
                return '#f54242'
            case 'COMMERCIAL':
                return '#5df542'
            case 'ENV':
                return '#4263f5'
            case 'NOISE':
                return '#da42f5'
            case 'RHYTHM':
                return '#42f5ad'
            case 'DROP':
                return '#f542b0'
            default:
                return '#f59e42'
        }
    }
}