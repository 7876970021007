import React from 'react'
import { Link, Outlet } from 'react-router-dom'
import Player from './Player'
import Banner from './Banner'
import Slogan from './Slogan'
import './App.css'
import Contact from "./Contact";

export default class App extends React.Component {

  render() {
    return <div className="App">
      <div className="TopBar">
        <div className="TopText">
          <div className="StationName">t spigot radio</div>
          <Slogan />
          <Contact />
        </div>
        <div className="Player">
          <Banner />
          <Player />
        </div>
      </div>
      <div className="PageContent">
        <Outlet />
      </div>
    </div>
  }
}
