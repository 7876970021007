import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter, Routes, Route, useLocation, useNavigate, useParams } from 'react-router-dom'
import { Navigate } from 'react-router'
import './index.css'
import App from './App'
import Auth from './Auth'
import Axes from './Axes'
import Dashboard from './Dashboard'
import Login from './Login'
import Operator from './Operator'
import Status from './Status'
import Track from './Track'
import Tracks from './Tracks'
import reportWebVitals from './reportWebVitals'

function withRouter(Component) {
  function ComponentWithRouterProp(props) {
    let location = useLocation()
    let navigate = useNavigate()
    let params = useParams()
    return (
      <Component
        {...props}
        router={{ location, navigate, params }}
      />
    )
  }

  return ComponentWithRouterProp
}

// Lame that we have to do this, but this is what it takes to get navigation
// into component classes.
const LoginWithRouter = withRouter(Login)
const OperatorWithRouter = withRouter(Operator)
const DashboardWithRouter = withRouter(Dashboard)
const TrackWithRouter = withRouter(Track)
const TracksWithRouter = withRouter(Tracks)

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <Routes>
        <Route path="/" element={ <App/> }>
          <Route path="login" element={ <LoginWithRouter/> } />
          <Route index element={ <Status/> } />
          <Route path="operator" element={
            Auth.userIsAuthenticated() ? <OperatorWithRouter/> : <Navigate to="/login" />
          }>
            <Route index element={ <DashboardWithRouter/> } />
            <Route path="tracks" element={ <TracksWithRouter/> } />
            <Route path="track/:trackId" element={ <TrackWithRouter/> } />
            <Route path="axes" element={ <Axes/> } />
          </Route>
        </Route>
        <Route path="*" element={
                <main style={{ padding: "1rem" }}>
                  <p>There's nothing here!</p>
                </main>
        } />
      </Routes>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
