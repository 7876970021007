import React from 'react'
import tuner from './radio-tuner.webp'
import { radioUrl } from './Config.js'
import AudioSpectrum from 'react-audio-spectrum'

export default class Player extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            playing: false,
            connecting: false,
            shouldBePlaying: false,
            volume: 100,
        }
        this.state.player = new Audio()
        this.state.player.crossOrigin = 'anonymous'
        this.state.player.preload = 'none'

        this.setVolume = this.setVolume.bind(this)
        this.tryToPlay = this.tryToPlay.bind(this)
        this.checkReconnect = this.checkReconnect.bind(this)
        this.renderButton = this.renderButton.bind(this)
    }

    componentDidMount() {
        this.reconnectTask = setInterval(this.checkReconnect, 2000)
    }

    componentWillUnmount() {
        clearInterval(this.reconnectTask)
    }

    checkReconnect() {
        const ended = this.state.player.ended
        if (ended) {
            this.setState({ playing: false })
        }
        if (!this.state.playing && this.state.shouldBePlaying) {
            this.tryToPlay()
        }
    }

    tryToPlay() {
        this.setState({ connecting: true })
        this.state.player.src = `${radioUrl}?${ Date.now() }`
        this.state.player.load()
        this.state.player.play()
            .then( () => {
                this.setState({ connecting: false, playing: true, shouldBePlaying: true })
            }).catch( err => {
            this.setState({ connecting: false, playing: false, shouldBePlaying: true })
        })
    }

    setVolume(event) {
      const volume = event.target.value
      this.state.player.volume = volume / 100
      this.setState({ volume: volume })
    }

    renderButton() {
        if (this.state.connecting) {
            return <span className="material-icons">hourglass_bottom</span>
        } else if (this.state.playing) {
            return <span className="material-icons">pause</span>
        } else {
            return <span className="material-icons">play_arrow</span>
        }
    }

    render() {
        return <div>
            <img className="Tuner" src={ tuner } width="100% "/>
            <button className="PlayerButton" onClick={ () => {
                if (this.state.playing) {
                    this.state.player.pause()
                    this.setState({ playing: false, shouldBePlaying: false })
                } else {
                    this.tryToPlay()
                }
            }}>
            { this.renderButton() }
            </button>
            <div className="Spectrum">
                <AudioSpectrum
                    className="Analyzer"
                    height={ 30 }
                    width={ 182 }
                    meterWidth={ 8 }
                    meterCount={ 20 }
                    meterColor={[
                        {stop: 0, color: '#66f'},
                        {stop: 0.5, color: '#00c'},
                        {stop: 1, color: '#000'}
                    ]}
                    gap={ 1 }
                    audioEle={ this.state.player }
                />
            </div>
            <div className="VolumeContainer">
                <span className="material-icons VolumeUp">volume_up</span>
                <input type="range" className="VolumeSlider" value={ this.state.volume } onChange={ this.setVolume } />
            </div>
        </div>
    }
}
