import React from 'react'
import Ansi from 'ansi-to-react'
import Api from './Api'

export default class LogTailer extends React.Component {

    constructor(props) {
        super(props);
        this.state = { logs: [] }

        this.updateData = this.updateData.bind(this)
    }

    componentDidMount() {
        this.updateData()
        this.updateTask = setInterval(this.updateData, 5000)
    }

    componentWillUnmount() {
        clearInterval(this.updateTask)
    }

    updateData() {
        Api.tailLogs().then( data => this.setState({ logs: data.split("\n") }) )
    }

    render() {
        return <div className="Logs">
            { this.state.logs.map( (line, index) => <pre key={ index }><Ansi>{ line }</Ansi></pre> ) }
        </div>
    }
}
