import React from 'react'
import Api from './Api.js'
import moment from 'moment'

export default class History extends React.Component {

    constructor(props) {
        super(props);
        this.state = { history: [] }

        this.updateData = this.updateData.bind(this)
    }

    componentDidMount() {
        this.updateData()
        this.updateTask = setInterval(this.updateData, 5000)
    }

    componentWillUnmount() {
        clearInterval(this.updateTask)
    }

    updateData() {
        Api.history().then( data => this.setState({ history: data }) )
    }

    timeForEpoch(epoch) {
        const utcMoment = moment.utc(epoch * 1000)
        const utcDate = utcMoment.toDate()
        const localMoment = moment(utcDate).local()
        return localMoment.format('HH:mm:ss')
    }

    render() {
        // Now Playing data looks like: [{"id":33,"album":"","artist":"tspigot Radio Bump","duration":18.050612,"path":"/media/bumpH_nonsense.mp3","playWindow":"BOTH","title":"bumpH_nonsense","type":"BUMP","lastPlayEpoch":1641701576},{"id":772,"album":"","artist":"Tom Paolantonio, Adam Primack","duration":167.836735,"path":"/media/quiet_stream.mp3","playWindow":"BOTH","title":"quiet_stream","type":"ENV","lastPlayEpoch":1641171967}]
        const historyTrackTypes = [ 'FULL_TRACK', 'BED', 'AMBIENCE', 'VOICE', 'COMMERCIAL' ]
        return <table className="History">
          <tbody>
          {
            this.state.history
                .filter( track => historyTrackTypes.includes(track.type) )
                .slice(0, 20)
                .map( track =>
                  <tr key={ track.id }>
                    <td className="Time">{ this.timeForEpoch(track.lastPlayEpoch) }</td>
                    <td className="Track">
                      <span className="Title"><i><b>{ track.title }</b></i></span>
                      <span className="Artist">{ track.artist ? " by " + track.artist : "" }</span>
                    </td>
                  </tr>
                )
          }
          </tbody>
        </table>
    }
}
