import React from 'react'
import Api from './Api'
import Auth from './Auth'

export default class Login extends React.Component {

    constructor(props) {
        super(props);
        this.state = { error: "", username: "", password: "" }

        this.checkCredentials = this.checkCredentials.bind(this)
        this.saveCredentials = this.saveCredentials.bind(this)
    }

    checkCredentials() {
        Api.checkCredentials(this.state.username, this.state.password)
            .then( res => {
                this.saveCredentials()
                this.props.router.navigate("/operator")
            })
            .catch( error => {
              console.log(error)
              this.setState({ error: 'Authentication Failed'})
            })
    }

    saveCredentials() {
        localStorage['username'] = this.state.username
        localStorage['password'] = this.state.password
    }

    render() {
        return <div>
          <div className="LoginContainer">
            <table className="LoginBox">
              <tbody>
              <tr>
                <td>
                  <div className="Label">
                    Username
                  </div>
                </td>
                <td>
                  <div className="Value">
                    <input className="TextInput" type="text" value={ this.state.username }
                      onChange={ (event) => this.setState({ username: event.target.value }) } />
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <div className="Label">
                    Password
                  </div>
                </td>
                <td>
                  <div className="Value">
                    <input className="TextInput" type="password" value={ this.state.password }
                      onChange={ (event) => this.setState({ password: event.target.value }) } />
                  </div>
                </td>
              </tr>
              <tr>
                <td colSpan="2">
                  <button className="LoginBox" onClick={ this.checkCredentials }>Log In</button>
                </td>
              </tr>
              </tbody>
            </table>
        </div>
        <div className="error">
          <span>{ this.state.error }</span>
        </div>
      </div>
    }
}
